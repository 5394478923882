import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { submitMission } from '../../../services/learnAndEarn.service';
import { toast } from 'react-toastify';

//Image
import Export from "../../../assets/images/TransactionHistory/export.png";

function RewardModal({ show, onHide, missionDetail, handleClose }) {
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
    mode: "onBlur",
  });

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    reset();
    if (missionDetail?.inputs) {
      missionDetail.inputs.forEach((input, index) => {
        setValue(`inputs[${index}].value`, input.value);
      });
    } else {
      reset();  // Reset form when there are no inputs or missionDetail changes
    }
  }, [missionDetail, setValue, reset]);
  
  const onSubmit = async (formData) => {
    setLoading(true);
    const transformedData = missionDetail?.inputs?.length
      ? {
          inputs: formData.inputs.map((input, index) => ({
            [`inputs[${index}][mission_input_id]`]: input.mission_input_id,
            [`inputs[${index}][value]`]: input.value,
          })),
        }
      : {};

    try {
      const result = await submitMission(missionDetail?.id, transformedData);
      if (result?.data?.message) {
        toast.success(result.data.message);
        reset();  // Clear form after successful submission
      } else {
        toast.error(result?.response?.data?.message);
      }
    } catch (error) {
      toast.error("An error occurred while submitting the mission");
    } finally {
      setLoading(false);
      handleClose(false);
    }
  };

  const downloadPdf = (url) => {
    
    fetch(url).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document?.createElement('a');
        alink.href = fileURL;
        alink.download = url?.split("/")[url?.split("/")?.length - 1];
        alink.click();
      });
      });
  }
  
  

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header className="px-3 pb-0 border-0" closeButton>
        <Modal.Title>
          <h3 className="fw-bold">{missionDetail?.title}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} className="w-100">
          <div>
            
            <div
              className="left-inner-container word-wrap"
              dangerouslySetInnerHTML={{
                __html: missionDetail?.description?.replace(/<br\s*\/?>/gi, ''),
              }}
            ></div>

           {/* {missionDetail?.files?.map((item, index)=>{
            return(
              <div className='d-flex gap-2 my-3' key={index}>
                <div className='fw-bold'>{item?.original_filename}</div>
                <div className='cursor-pointer' onClick={()=>downloadPdf(item?.file)}><img src={Export} alt='download' style={{width:'15px'}}/></div>
              </div>
            )
           }) } */}
            {missionDetail?.inputs[0] && <div className="fw-semibold">Your Task</div>}
            {missionDetail?.inputs?.map((item, index) => (
              <div className="my-2" key={index}>
                <div className="fs-6">{item?.label}</div>
                <input
                  type="hidden"
                  value={item?.mission_input_id}
                  {...register(`inputs[${index}].mission_input_id`)}
                />
                <input
                  className={`form-control my-2 ${missionDetail?.submission?.submitted_at ? 'text-secondary':''}`  }
                  type="text"
                  disabled={item?.read_only}
                  placeholder="Enter your submission here"
                  {...register(`inputs[${index}].value`, {
                    required: true,
                    maxLength: item?.length,
                    validate: (value) => {
                      if (item?.type === 'link' && !/^https?:\/\/[^\s$.?#].[^\s]*$/.test(value)) {
                        return `Please enter a valid URL, Maximum length: ${item?.length}`;
                      }
                      if (item?.type === 'email' && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                        return `Please enter a valid email address, Maximum length: ${item?.length}`;
                      }
                      if (item?.type === 'number' && !/^\d+$/.test(value)) {
                        return `Please enter a valid Number, Maximum length: ${item?.length}`;
                      }
                      return true;
                    }
                  })}
                  onInput={(e) => {
                    if (e.target.value.length > item?.length) {
                      e.target.value = e.target.value.slice(0, item?.length);
                    }
                  }}
                />
                {errors.inputs?.[index]?.value && !item?.read_only && (
                  <small className="text-danger">
                    {errors.inputs[index].value.message || "This field is required"}
                  </small>
                )}
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-end w-100 mt-3">
            <div className="d-flex w-50">
              <button
                type="button"
                className="form-control text-primary btn mx-2 border solid"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="form-control btn btn-primary"
                disabled={isLoading || missionDetail?.submission?.submitted_at}
              >
                {isLoading ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default RewardModal;
