import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "../../components/common/layout/layout";
import Button from "../../components/common/button/Button";
import VerticalCard from "../../components/common/cards/courseCards/VerticalCard";

//import styles
import "../../assets/scss/pages/search/search.scss";
// import { searchService } from "../../services/dashboard.service";
import { searchServiceNew } from "../../services/dashboard.service";
import { Link } from "react-router-dom";



const SearchPage = () => {
    let [searchParams] = useSearchParams();
    const [searchedResult, setSeachedResult] = useState();
    const [searchedResultNew, setSeachedResultNew] = useState();

    const fetchSearchResult = async () => {
        const result = await searchServiceNew({
            searchQuery: decodeURIComponent(searchParams?.get("search")),
        });
        setSeachedResult(result?.data?.classes?.data);
        setSeachedResultNew(result?.data?.classes)
    };

    useEffect(() => {
        fetchSearchResult();
        // eslint-disable-next-line
    }, [searchParams]);

   

    // const groupByClassType = (searchedResult || []).reduce((acc, classes) => {
    //     const key = classes?.class_type?.name;
    //     if (key) {
    //         if (!acc[key]) {
    //             acc[key] = [];
    //         }
    //         acc[key].push(classes);
    //     }
    //     return acc;
    // }, {});

    return (
        <Layout currentPage="Search">
            <div className="">
                <h4 className="fw-bold mx-md-5 mx-3 mb-4 mt-4 text-capitalize">
                    Courses related to {decodeURIComponent(searchParams?.get("search"))}
                </h4>
                {/* {searchedResult?.length > 0 &&
                    Object?.keys(groupByClassType)?.map((searchedItem) => {
                        return (
                            <>
                                <div className="d-flex align-items-center justify-content-between flex-wrap mx-md-5 mx-3 mb-3">
                                    <p className="OtherCourseListing-main-text fw-bold fs-5">
                                        {searchedItem}
                                    </p>
                                    <div>
                                        <Button name="View all courses" btnType="whiteButton" />
                                    </div>
                                </div>{" "}
                                <div className="card-main-container mx-md-5 mx-3 mb-4 d-flex flex-wrap gap-4">
                                    {groupByClassType?.[searchedItem]?.map((nestedSearchItem) => {
                                        return (
                                            <>
                                                <VerticalCard cardContent={nestedSearchItem} />
                                            </>
                                        );
                                    })}
                                </div>
                            </>
                        );
                    })} */}

                {searchedResultNew?.length > 0 &&
                    searchedResultNew.map((item, index) => (
                        <div key={index}>
                            <div className="d-flex align-items-center justify-content-between flex-wrap mx-md-5 mx-3 mb-3">
                                <p className="OtherCourseListing-main-text fw-bold fs-5">
                                    {item?.classType?.name}
                                </p>
                                {item?.has_more && (
                                    <Link className='text-dark text-decoration-none' to={`/classes/${item?.classType?.slug}?search=${decodeURIComponent(searchParams?.get("search"))}`}>
                                        <Button name="View all courses" btnType="whiteButton" />
                                    </Link>
                                )}
                            </div>
                            <div className="card-main-container mx-md-5 mx-3 mb-4 d-flex flex-wrap gap-4">
                                {item?.classes?.map((nestedSearchItem, nestedIndex) => (
                                    <VerticalCard 
                                        key={nestedIndex} 
                                        cardContent={nestedSearchItem} 
                                    />
                                ))}
                            </div>
                        </div>
                    ))}

            </div>
        </Layout>
    );
};

export default SearchPage;
