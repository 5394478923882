import { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';

import PrivateRoute from "./utils/PrivateRoute";

//importing components
import Home from "./pages/home/Home";
import SignUp from "./pages/auth/SignUp";
import OnLiveDetailPage from "./pages/onLiveDetailPage/OnLiveDetailPage";
import InterestSelectionPage from "./pages/InterestSelectionPage/InterestSelectionPage";
import ForgetPassword from "./pages/auth/ForgetPassword";
import OtpPage from "./pages/auth/OtpPage";
import OnTheGo from "./pages/onTheGo/OnTheGo";
import OnTheGoDetailPage from "./pages/onTheGoDetailPage/OnTheGoDetailPage";
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import CategorySelectionPage from "./pages/CategorySelection/CategorySelectionPage";
import ChangePasswordSuccess from "./pages/auth/SuccessPages/ChangePasswordSuccess";
import SearchPage from "./pages/searchPage/SearchPage";
import SubscriptionPage from "./pages/subscription/SubscriptionPage";
import ExploreAllClasses from "./pages/onLive/ExploreAllClasses";
import Profile from "./pages/profile/Profile";
import MyLearning from "./pages/myLearning/MyLearning";
import SubscriptionDetail from "./pages/subcriptionDetail/SubscriptionDetail";
import Payment from "./pages/payment/Payment";
import Assessment from "./pages/assesment/Assessment";

// Scroll Component
import ScrollToTop from './components/common/scrolltotop';

import { getClassType } from "./services/dashboard.service";
import { getFeaturedCategories } from "./services/category.service";
import { getUserProfile } from "./services/user.service";
import { getCountryList, logout } from "./services/auth.service";
import { getSubscriptionStatus } from "./services/subscription.service";

import { setCategoryList } from "./redux/categorySlice";
import { setClassTypes } from "./redux/classSlice";
import { selectToken, setCountryList, setToken, setUserDetail } from "./redux/userSlice";
import { setSubscriptionDetail } from "./redux/subscriptionSlice";
import Certificate from "./components/Certificate/Certificate";

import AllCategory from "./pages/CategorySelection/AllCategory";
import AboutUs from "./pages/content/AboutUs";
import PrivacyPolicy from "./pages/content/PrivacyPolicy";
import TermsAndConditions from "./pages/content/TermsAndConditions";
import CoachReview from "./components/CoachReview/CoachReview";
import TransactionHistory from "./pages/TransactionHistory/TransactionHistory";
import RedeemGiftCard from "./pages/RedeemGiftCard/RedeemGiftCard";
import AllCoaches from "./pages/AllCoaches/AllCoaches";
import ChangePassword from "./pages/changePassword/ChangePassword";
import UserCertificates from "./pages/certificates/UserCertificates";
import CertificateAuthentication from "./pages/certificates/CertificateAuthentication";
import UpcomingClasses from "./pages/upcomingClasses/UpcomingClasses";
import LearnAndEarnSubscribe from "./pages/learnAndEarn/LearnAndEarnSubscribe";
import PageNotFound from "./pages/404/404";
import LearnAndEarnTotalPoints from "./pages/learnAndEarn/LearnAndEarnTotalPoints";
import ReffralReward from "./pages/learnAndEarn/ReffralReward";
import LearningReward from "./pages/learnAndEarn/LearningReward";
import RewardMission from "./pages/learnAndEarn/RewardMission";
import CertificateDetails from "./pages/certificates/CertificateDetails";
import axios from "axios";
import { removeUserSession } from "./utils/Auth.Service";
import RewardWithdrawal from "./pages/learnAndEarn/ClaimRewards/RewardWithdrawal";
import BeneficiaryDetails from "./pages/learnAndEarn/ClaimRewards/BeneficiaryDetails";
import LearnAndEarnTnC from "./pages/learnAndEarn/LearnAndEarnTnC";
import PaymentTnC from "./pages/payment/PaymentTnC";
import { subscriptionStatusLearnAndEarn  } from "./services/learnAndEarn.service";
import { setLearnAndEarnSubscriptionDetail } from "./redux/learnAndEarnSubscriptionSlice";

const App = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(selectToken)

    useEffect(() => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.getRegistrations().then((registrationsArray) => {
                if (registrationsArray?.length > 0) {
                    registrationsArray[0].update();
                }
            });
        }
    }, [location]);

    const handleLogoutClick = async () => {
        // await logout();
        dispatch(setToken(null));
        dispatch(setUserDetail(null))
        removeUserSession();
        navigate("/login");
    };

    axios.interceptors.response.use((response) => {
        return response
    }, async function (error) {
        if (error.response.data.message === "Unauthenticated.") {
            handleLogoutClick()
        }
        return Promise.reject(error);
    });

    // for preventing the installation popup on browser
    window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault()
    })

    const fetchUserProfile = async () => {
        const result = await getUserProfile();
        dispatch(setUserDetail(result?.data?.user));
    };

    
    const fetchClassTypes = async () => {
        try {
            const result = await getClassType();
            dispatch(setClassTypes(result?.data?.classTypes));
        } catch (error) {
            console.log(error);
        }
    };

    /**
        * @function fetchCountryList
        * @param
        * @description This function is used fetch the country List
    */
    const fetchCountryList = async () => {
        const result = await getCountryList();
        const { data } = result;
        dispatch(setCountryList(data?.countries));
    };

    /**
        * @function fetchCategory
        * @param
        * @description This function is used fetch category List
    */
    const fetchCategory = async () => {
        try {
            const result = await getFeaturedCategories({
                highlight:false
            });
            dispatch(setCategoryList(result?.data?.categories));
        } catch (error) {
            console.log(error);
        }
    };

    /**
        * @function fetchUserSubscriptionStatus
        * @param
        * @description This function is used fetch user subscription status
    */
    const fetchUserSubscriptionStatus = async () => {
        try {
            const result = await getSubscriptionStatus();
            dispatch(setSubscriptionDetail(result?.data));
        } catch (error) {
            console.log(error);
        }
    };

    const fetchUserLNPSubscriptionStatus = async () => {
        const result = await subscriptionStatusLearnAndEarn();
        dispatch(setLearnAndEarnSubscriptionDetail(result?.data));
    };

    useEffect(() => {
        if (token) {
            fetchUserProfile();
            fetchUserSubscriptionStatus();
            fetchUserLNPSubscriptionStatus();
        }
        // eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        fetchClassTypes();
        fetchCountryList();
        fetchCategory();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/classes/:slug" element={<ExploreAllClasses />} />
                <Route path="/onthego" element={<OnTheGo />} />
                <Route path="/onthego-explore" element={<OnTheGoDetailPage />} />

                <Route path="/class/:slug" element={<OnLiveDetailPage />} />
                <Route path="/catagory" element={<SearchPage />} />

                {/* USER ACCOUNT PAGES */}
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/login" element={<SignIn />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/reset-password-success" element={<ChangePasswordSuccess />} />

                <Route path="/forget-password" element={<ForgetPassword />} />
                <Route path="/otp-verification" element={<OtpPage />} />
                <Route path="/profession-selection" element={<InterestSelectionPage />} />
                <Route path="/category-selection" element={<CategorySelectionPage />} />

                {/* Private Route */}
                <Route element={<PrivateRoute />}>
                    <Route path="/account/profile" element={<Profile />} />
                    <Route path="/my-learning" element={<MyLearning />} />

                    {/* SUBSCRIPTION */}
                    <Route path="/subscription" element={<SubscriptionPage />} />
                    <Route path="/account/subscription" element={<SubscriptionDetail />} />
                    {/* Transaction History */}
                    <Route path="/account/transaction-history" element={<TransactionHistory />} />
                    {/* RedeemGiftCard */}
                    <Route path="/redeem" element={<RedeemGiftCard />} />
                    {/* ChangePassword */}
                    <Route path="/account/change-password" element={<ChangePassword />} />
                    {/* Certificates */}
                    <Route path="/account/certificate" element={<UserCertificates />} />
                    <Route path="/subscription/payment" element={<Payment />} />

                    {/* Assessment */}
                    <Route path="/assessment/:slug" element={<Assessment />} />
                    {/* Certificate */}
                    <Route path="/certificate/:slug" element={<Certificate />} />
                    {/* Learn-and-Earn */}
                    <Route path="/account/learn-and-earn" element={<LearnAndEarnSubscribe />} />
                    <Route path="/account/learn-and-earn/total-points" element={<LearnAndEarnTotalPoints />} />
                    <Route path="/account/learn-and-earn/reffral-reward" element={<ReffralReward />} />
                    <Route path="/account/learn-and-earn/learning-reward" element={<LearningReward />} />
                    <Route path="/account/learn-and-earn/reward-mission" element={<RewardMission />} />
                    <Route path="/account/learn-and-earn/claim-reward" element={<RewardWithdrawal />} />
                    <Route path="/account/learn-and-earn/beneficiary-details" element={<BeneficiaryDetails />} />
                </Route>

                {/* Certificate */}
                <Route path="/certificate/verify" element={<CertificateAuthentication />} />
                <Route path="/certificate/details" element={<CertificateDetails />} />
                {/* T&C */}
                <Route path="/account/learn-and-earn/term-and-condition" element={<LearnAndEarnTnC />} />
                <Route path="/subscription/payment-Tearm-and-Condition" element={<PaymentTnC />} />


                {/* AllCATEGORY */}
                <Route path="/all-category" element={<AllCategory />} />
                {/* CoachReview */}
                <Route path="/coach/:slug" element={<CoachReview />} />
                {/* AllCoaches */}
                <Route path="/explore-all-coaches" element={<AllCoaches />} />
                {/* About Us */}
                <Route path="/about" element={<AboutUs />} />
                {/* Privacy Policy */}
                <Route path="/privacy" element={<PrivacyPolicy />} />
                {/* Terms And Conditions */}
                <Route path="/terms" element={<TermsAndConditions />} />
                {/* UpComing Classes */}
                <Route path="/upcoming-classes" element={<UpcomingClasses />} />

                {/* 404 */}
                <Route path="*" element={<PageNotFound />} />

                {/* image paths */}
                <Route
                    path="W_ReSkills-Logo-TM.png"
                    element={null} // No rendering for the image path
                />
                <Route
                    path="/P_ReSkills-Logo-TM_purple.png"
                    element={null} // No rendering for the image path
                />
                <Route
                    path="/sitemap.xml"
                    element={null} // No rendering for the image path
                />

            </Routes>
            <ToastContainer />
        </>
    );
}

export default App;
