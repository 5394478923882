import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";

import { Modal } from "react-bootstrap";

//importing images
import noProfileAvatar from "../../assets/images/Profile/noProfileAvatar.svg";
import check from "../../assets/images/Profile/check.svg";
import cross from "../../assets/images/Profile/cross.svg";

//importing components
import Button from "../common/button/Button";

//importing styles
import "../../assets/scss/components/modal/editProfile.scss";
import Input from "../common/input/Input";
import { useSelector } from "react-redux";
import {
  selectCountryList,
  selectUserDetails,
  setUserDetail,
} from "../../redux/userSlice";
import Dropdown from "../common/dropdown/Dropdown";
import { updateUserProfile } from "../../services/user.service";

const EditProfile = ({ show, setShow }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState();
  const userDetail = useSelector(selectUserDetails);
  const [gender, setGender] = useState();
  const countryList = useSelector(selectCountryList);
  const [selectedCountry, setSelectedCountry] = useState();
  const [imageSelection, setImageSelection] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountryId , setSelectedCountryId]= useState()
  const [formData, setFormData] = useState({
    first_Name: "",
    last_Name: "",
    phone_number: "",
    email: "",
    news_letter_subscription: "",
  });
  const handleClose = () => {
    setImageSelection(false)
    setSelectedImage(null)
    setShow(false)
  };
  

  const handleEditProfile = async () => {
    setIsLoading(true);
    const result = await updateUserProfile({
      ...(formData?.first_Name && {
        first_name: formData?.first_Name || userDetail?.first_name,
      }),
      ...(formData?.last_Name && {
        last_name: formData?.last_Name || userDetail?.last_name,
      }),
      ...(formData?.email && { email: formData?.email || userDetail?.email }),
      ...(formData?.phone_number && {
        phone_number: formData?.phone_number || userDetail?.phone_number,
      }),

      ...(startDate && { dob: startDate }),

      ...(gender && { gender: gender }),

      ...(selectedCountry && { country_id: selectedCountry }),
      ...(formData?.news_letter_subscription && {
        news_letter_subscription: formData?.news_letter_subscription || userDetail?.news_letter_subscription ,
      }),
      ...(selectedImage && {
        image: selectedImage === "noProfile" ? null : selectedImage,
      }),
    });

    setIsLoading(false);
    if (result?.data?.status === "success") {
      dispatch(setUserDetail(result?.data?.user));
      toast.success(
        <div>
          <p className="m-0">
            Account detail updated.{" "}
            {((result?.data?.user?.name_updated === 1 && formData?.first_Name) || (result?.data?.user?.name_updated === 1 && formData?.last_Name)) && (
              <span>Please contact admin for changing your name</span>
            )}
          </p>
        </div>
      );

      handleClose();
    } else {
      toast.error(
        result?.response?.data?.errors?.email[0]
          ? result?.response?.data?.errors?.email[0]
          : "Something went wrong"
      );
    }
  };

  /**
   * @function handleGetData
   * @param {values}
   * @description This function is used to set the email and password in formdata
   */

  const handleGetData = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    if (name === "news_letter_subscription") {
      setFormData({
        ...formData,
        [name]: checked === true ? "1" : "0",
      });
    } else {
      setFormData({
        ...formData,
        [name]: checked,
      });
    }
  };
  const getImageUrl = (userImage) => {
    try {
      var image = URL.createObjectURL(userImage);
      return image;
    } catch (error) {
      console.log(error);
    }
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedImage(selectedFile);
    }
  };
  return (
    <div className="editProfileModal-container">
      <Modal show={show} size="lg" onHide={handleClose}>
        {/* <img
          src={cross}
          alt="icon"
          className="position-absolute top-0 end-0 cursor-pointer"
          onClick={() => handleClose()}
        /> */}
        <Modal.Header className="px-4 pb-0 border-0" closeButton>
          <Modal.Title>
            <h3 className="fw-bold">Edit Personal information</h3>
          </Modal.Title>
        </Modal.Header>

        <div className="pb-4 px-4">
          <p className="fs-6 text-secondary">
            Add your personal information as you would like it to appear on your
            profile.
          </p>
          <div>
            <p>
              Profile Photo <span className="text-secondary">(Optional)</span>
            </p>
            {/* <div className="w-25 rounded-circle overflow-hidden"> */}
            {selectedImage !== null ? (
              <img
                src={
                  selectedImage === "noProfile"
                    ? noProfileAvatar
                    : getImageUrl(selectedImage)
                }
                alt="avatar-preview"
                className="w-25 rounded-circle overflow-hidden aspect-ratio-1x1"
              />
            ) : (
              <img
                src={userDetail?.image || noProfileAvatar}
                alt="avatar"
                className="w-25 rounded-circle overflow-hidden aspect-ratio-1x1"
              />
            )}
            {/* </div> */}
            <div className="d-flex gap-2 mt-3">
              {imageSelection ? (
                <div className="d-flex align-items-centre gap-4">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  <p
                    className="cursor-pointer"
                    onClick={() => setImageSelection(false)}
                  >
                    Cancel
                  </p>
                </div>
              ) : (
                <>
                  <Button
                    name="Change Photo"
                    onClick={() => setImageSelection(true)}
                  />
                  {/* <Button
                    name="Remove Photo"
                    btnType="whiteButton"
                    onClick={() => setSelectedImage("noProfile")}
                  /> */}
                </>
              )}
            </div>
            <div className="d-flex gap-3 mt-3">
              <div className="w-50">
                <label className="fs-6 text-secondary">First Name</label>
                <Input
                  name="first_Name"
                  placeholder="First Name"
                  defaultValue={userDetail?.first_name}
                  onChange={(e) => handleGetData(e)}
                />
              </div>
              <div className="w-50">
                <label className="fs-6 text-secondary">Last Name</label>
                <Input
                  name="last_Name"
                  placeholder="Last Name"
                  defaultValue={userDetail?.last_name}
                  onChange={(e) => handleGetData(e)}
                />
              </div>
            </div>
            <div className="d-flex gap-3 my-2">
              <div className="w-50">
                <label className="fs-6 text-secondary">Phone Number</label>
                <div className="d-flex gap-2">
                  <div className="w-30">
                    <input
                      name="country-code"
                      placeholder="Country Code"
                      className="form-control"
                      value={selectedCountryId || userDetail?.country?.isd_code || ""}
                      disabled={true}
                    />
                  </div>
                  <div className="w-100">
                    <Input
                      name="phone_number"
                      placeholder="Phone Number"
                      defaultValue={userDetail?.phone_number}
                      onChange={(e) => handleGetData(e)}
                    />
                  </div>
                  
                </div>
                
              </div>
              <div className="w-50">
                <label className="fs-6 text-secondary">Email Address</label>
                <Input
                  name="email"
                  placeholder="Email Address"
                  defaultValue={userDetail?.email}
                  onChange={(e) => handleGetData(e)}
                  disabled={true}
                  
                />
              </div>
            </div>
            <div className="d-flex gap-3">
              <div className="w-50">
                <label className="fs-6 text-secondary">Country</label>
                <Dropdown
                  options={countryList}
                  defaultOption={userDetail?.country_id}
                  setSelectedCountry={setSelectedCountry}
                  setSelectedCountryId={setSelectedCountryId}
                />
              </div>

              <div className="w-50">
                <label className="fs-6 text-secondary">Date of birth</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => setStartDate(e?.target?.value)}
                  defaultValue={userDetail?.dob}
                  max="2012-12-31" 
                />
              </div>
            </div>

            <div className="d-flex gap-2 my-4">
              <div className="w-50">
                {(gender || userDetail?.gender) === "male" ? (
                  <button className="btn btn-outline-primary bg-profile-gender btn-sm p-2 rounded fw-bold w-100">
                    <img src={check} alt="icon" /> Male
                  </button>
                ) : (
                  <div onClick={() => setGender("male")}>
                    <Button btnType="whiteButton" name="Male" />
                  </div>
                )}
              </div>
              <div className="w-50">
                {(gender || userDetail?.gender) === "female" ? (
                  <button className="btn btn-outline-primary bg-profile-gender btn-sm p-2 rounded fw-bold w-100">
                    <img src={check} alt="icon" />
                    Female
                  </button>
                ) : (
                  <div onClick={() => setGender("female")}>
                    <Button btnType="whiteButton" name="Female" />
                  </div>
                )}
              </div>
            </div>
            <div className="form-check d-flex gap-2 mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckChecked"
                name="news_letter_subscription"
                defaultChecked={userDetail?.news_letter_subscription === '1'}
                onChange={handleCheckBox}
              />
              <p className="m-0 fs-6">{t("newsLetter")}</p>
            </div>
            <div className="d-flex gap-2 mt-4 justify-content-end">
              <div style={{ width: "140px" }}>
                <Button
                  name="Cancel"
                  btnType="whiteButton"
                  onClick={() => handleClose()}
                />
              </div>
              <div style={{ width: "140px" }}>
                <Button
                  name={isLoading ? "Loading..." : "Save"}
                  onClick={() => handleEditProfile()}
                />
              </div>
            </div>
          </div>
          {/* <Lottie animationData={animation} loop={true}/> */}
        </div>
      </Modal>
    </div>
  );
};

export default EditProfile;
