import React, { useRef } from "react";
import moment from "moment";
import "../../../../assets/scss/pages/TransactionHistory/TransactionHistory.scss";
import Export from "../../../../assets/images/TransactionHistory/export.png";


const TransactionHistoryCard = ({ item, index }) => {
 

 

  if (!item) return null; // Return null if item is not provided

  return (
    <div className="my-3" key={`transaction-${index}`}>
      <div className="row d-flex shadow-sm rounded p-3 border border-light content-between w-100">
        <div className="tran-container col-7 d-flex justify-content-between">
          <div className="invoice-div pt-2">
            <small className="text-muted">Invoice</small>
            <div className="fw-semibold text-primary">{item?.invoice_no}</div>
          </div>
          <div className="fw-semibold pt-2 pt-1 mt-2-th small-display-none-tra">
            ${item?.amount}
          </div>
          <div className="pt-2 pt-1 mt-2-th col-4 date-width">
            {moment(item?.payment_date)?.format("DD MMMM YYYY")}
          </div>
          {/* <div className="wht-btn d-flex pt-2">
            <button
              className="btn btn-outline-secondary bg-white btn-sm py-1 px-2 rounded fw-bold wht-btn large-display-none-tra"
            //   onClick={downloadInvoiceAsJPG}
            >
              Reciept
              <img
                src={Export}
                alt="Export Receipt"
                className="mx-2"
                style={{ width: "13px", height: "13px" }}
              />
            </button>
          </div> */}
        </div>
        <div className="tran-container pt-2 mt-2-th col-4 large-end">
          <div
            className="Sucess-btn px-2 text-white fw-semibold text-capitalize"
            style={{
              background: item?.payment_status === "success" ? "#129155" : "#D92D20",
            }}
          >
            {item?.payment_status}
          </div>
          <div className="fw-semibold pt-2 pt-1 mt-2-th mx-2 large-display-none-tra">
            ${item?.amount}
          </div>
          {/* <div className="wht-btn d-flex pt-2">
            <button
              className="btn btn-outline-secondary bg-white btn-sm py-1 px-2 rounded fw-bold wht-btn small-display-none-tra"
            //   onClick={downloadInvoiceAsJPG}
            >
              Reciept
              <img
                src={Export}
                alt="Export Receipt"
                className="mx-2"
                style={{ width: "13px", height: "13px", marginBottom: "3px" }}
              />
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TransactionHistoryCard;
