import axios from "axios";

/**
    * @function getBannerList
    * @param
    * @description This function is used to get all the banner
*/
export const getBannerList = async () => {
    try {
        const result = await axios.get(`admin/banner-list`);
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function searchService
    * @param { searchQuery }
    * @description This function is used to search any classes
*/
export const searchService = async ({ searchQuery = "" }) => {
    try {
        const result = await axios.get(`admin/class-list?search=${searchQuery ? searchQuery : null}&page=1&per_page=10`);
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function searchService
    * @param { searchQuery }
    * @description This function is used to search any classes
*/
export const searchServiceNew = async ({ searchQuery = "" }) => {
    try {
        const result = await axios.get(`admin/class-list-search?search=${searchQuery ? searchQuery : null}&page=1&per_page=10&limit_per_class_type=3`);
        return result;
    } catch (error) {
        return error;
    }
};
/**
    * @function getClassService
    * @param { date, filter, classSlug, searchQuery, perPageItem, page, language, category }
    * @description This function is used to fetch the classes
*/
export const getClassService = async ({ date = "", filter = "", classSlug = "", searchQuery = "", perPageItem = "12", page = "", language = "", category = "",up_coming_classes ="" ,up_coming_date="" ,user_id=""}) => {
    
    try {
        const result = await axios.get(
            `admin/class-list?${classSlug && "&class_slug=" + classSlug}${searchQuery && searchQuery !== null && "&search=" + searchQuery
            }${language && "&language_id=" + language}${category && "&category_slug=" + category
            }${page && "&page=" + page}${perPageItem && "&per_page=" + perPageItem}${date && "&date=" + date
            }${filter && "&class_type_slug=" + filter}${(filter?.toLowerCase() === 'onlive' || filter?.toLowerCase() === 'todays-classes' || filter?.toLowerCase() === 'onstage'  || filter?.toLowerCase() === 'onference' ) && up_coming_date ? "&up_coming_date=" + up_coming_date : ''}${(filter?.toLowerCase() === 'onlive' || filter?.toLowerCase() === 'todays-classes' || filter?.toLowerCase() === 'onstage' || filter?.toLowerCase() === 'onference' ) && up_coming_classes ? "&up_coming_classes=" + up_coming_classes : ''} ${user_id && "&user_id=" + user_id}`
        );

        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function getDashboardStats
    * @param
    * @description This function is used to fetch the dashboard Stats
*/
export const getDashboardStats = async () => {
    try {
        const result = await axios.get(`admin/dashboard-stats-list`);
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function getClassType
    * @param
    * @description This function is used to fetch the class type
*/
export const getClassType = async () => {
    try {
        const result = await axios.get(`admin/class-type-list`);
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function getReviews
    * @param
    * @description This function is used to fetch all the reviews
*/
export const getReviews = async () => {
    try {
        const result = await axios.get(`admin/write-ups-list`);
        return result;
    } catch (error) {
        return error;
    }
};
