import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Navbar from "../common/navbar/Navbar";
import Footer from "../common/footer/Footer";
import BgImg from "../../assets/images/CoachReview/bg.jpg";
import { IoMdStar } from "react-icons/io";

import Avatar from "../../assets/images/CoachReview/Avatar.png";
import ratingIcon from "../../assets/images/Rating/ratingIcon.svg";
import SiteSlider from "../common/slider/SiteSlider";
import Button from "../common/button/Button";
import "../../assets/scss/components/CoachReview/CoachReview.scss";
import { Rating } from "react-simple-star-rating";
import CoachCard from "../common/cards/CoachCard/CoachCard";
import CoachCourceHistoryCard from "../common/cards/CoachCard/CoachCourceHistoryCard";
import Image from "../common/image";
import { getCoachDetail } from "../../services/coach.service";
import NoData from "../common/noData/NoData";
import CoachHistoryPaginate from "../common/paginate/CoachHistoryPaginate";
import { useDispatch } from "react-redux";
import { setCoach } from "../../redux/coachSlice";

const CoachReview = () => {
    const { slug } = useParams();
    const [notFound, setNotFound] = useState(false);
    const [coachDetail, setCoachDetail] = useState();
    const[activeTab ,setActiveTab] = useState('all-class')
    const dispatch = useDispatch();

    const fetchCoachDetail = async () => {
        setNotFound(false)
        const result = await getCoachDetail({
            id:slug,
            page:1
        });
        dispatch(setCoach(result?.data));
        if (result?.response?.data?.message === "Coach not found.") {
            setNotFound(true)
        } else {
            setCoachDetail(result?.data)
        }
    };


    useEffect(() => {
        fetchCoachDetail()
        // eslint-disable-next-line
    }, [slug])

    const parseHTMLString = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const paragraphs = doc.querySelectorAll('p');
        return Array.from(paragraphs).map(p => p.textContent);
    };

    const items = parseHTMLString(coachDetail?.coach?.expertise);


    return (
        <div>
            <Navbar />
            {notFound ?
                <div className="card-container w-100">
                    <NoData page="not-found" />
                </div>
                :
                <>

                    <div className="d-flex justify-content-center my-2 ">
                        <div class="card coach-review-width">
                            <Image 
                                src={coachDetail?.coach?.cover_image ? coachDetail?.coach?.cover_image :BgImg } 
                                alt="bg" 
                                className="coach-bgImg" 
                                defaultImage={BgImg}
                            />
                            <div className='main-icon-container'>
                                <img src={coachDetail?.coach?.avatar} alt='' className='coach-icon' />
                                {/* <div className='small-icon-container d-flex mt-2'>
                            <div className='small-icons mx-2'></div>
                            <div className='small-icons mx-2'></div>
                            <div className='small-icons mx-2'></div>
                        </div> */}
                            </div>

                            <div class="card-body coach-profile ">
                                <h3 className="fw-semibold">{coachDetail?.coach?.full_name}</h3>
                                <div className="d-flex">
                                    <Rating
                                        initialValue={coachDetail?.coach?.average_rating}
                                        value={coachDetail?.coach?.average_rating}
                                        customIcons={ratingIcon}
                                        readonly={true}
                                        size={20}
                                        allowFraction={true}
                                    />
                                    <div className="p-1 text-muted">{Math.floor(coachDetail?.coach?.average_rating * 10) / 10}/5</div>
                                </div>
                                <div className="mt-3">
                                    <div>
                                        {coachDetail?.coach?.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="d-flex justify-content-center">
                        <div className="Coach-card-container coach-review-width d-flex justify-content-between w-100 gap-2">
                            <div className="col-6">
                                <div className="card shadow-sm bg-white rounded px-3 py-2 cursor-pointer coach-h">
                                    <h5>Expertise</h5>
                                    <div>
                                        <ol>
                                            {items.map((item, index) => (
                                                <li key={index}>{item}</li>
                                            ))}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card shadow-sm bg-white rounded px-3 py-2 cursor-pointer coach-h margin-l ">
                                    <h5>Qualifications & Achievement</h5>
                                    <ol>
                                        <li>Finantial Planing</li>
                                        <li>Finantial Literacy</li>
                                        <li>Francasing</li>
                                        <li>Adulting</li>
                                        <li>Accounting</li>
                                        <li>Finance</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className=" my-5 custom-container max-w-home">
                        <div>
                            <div className="course-alignment">
                                <h2 className=" fw-bold fs-4 mb-4 mx-2">Courses</h2>
                                <div className="d-flex course-bullets  ">
                                <div 
                                    className={`mx-2 fw-semibold cursor-pointer ${activeTab === 'all-class' ? 'bullet' : ''}`} 
                                    onClick={() => setActiveTab('all-class')}
                                    >
                                        All {coachDetail?.coach?.total_classes}
                                    </div>
                                    <div 
                                        className={`mx-2 fw-semibold cursor-pointer ${activeTab === 'previous-class' ? 'bullet' : ''}`} 
                                        onClick={() => setActiveTab('previous-class')}
                                        >
                                        Previous
                                    </div>
                                    <div 
                                        className={`mx-2 fw-semibold cursor-pointer ${activeTab === 'upcoming-class' ? 'bullet' : ''}`} 
                                        onClick={() => setActiveTab('upcoming-class')}
                                        >
                                        Upcoming
                                    </div>
                                </div>
                            </div>


                            <div className="d-flex justify-content-center mt-4">
                                <div className="Coach-card-container coach-review-width row w-100 ">
                                    {coachDetail?.all_classes?.data?.length > 0 ? 
                                         
                                            <CoachHistoryPaginate slug={slug} activeTab={activeTab} />
                                       
                                     :
                                        <NoData page="coach-Cources-History" />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Coach-main-container overflow-x-hidden custom-container max-w-home">
                        <div className="">
                            {coachDetail?.coach &&
                                <div className="d-flex align-items-center justify-content-between flex-wrap ">
                                <h2 className=" fw-bold fs-4 mb-4 mx-3 d-flex">  
                                    <div className="mx-1 py-1">
                                        Rates & Review
                                    </div>
                                    <div className="d-flex ">
                                    <Rating
                                        initialValue={coachDetail?.coach?.average_rating}
                                        value={coachDetail?.coach?.average_rating}
                                        customIcons={ratingIcon}
                                        readonly={true}
                                        size={20}
                                        allowFraction={true}
                                    />
                                    <div className="py-3 mx-1 text-muted fs-6 fw-normal">{Math.floor(coachDetail?.coach?.average_rating * 10) / 10}/5</div>
                                    <div className="py-3 text-muted fs-6 fw-normal">({coachDetail?.coach?.total_reviews?.toFixed(0)})</div>
                                </div>
                                 </h2>
                                <div className="mb-3 view-all">
                                    {/* <Button name="View All Review" btnType="white" /> */}
                                </div>
                            </div>}
                            {coachDetail?.reviews?.length > 0 ?
                                <SiteSlider
                                    cardType="horizental"
                                    noOfItems={coachDetail?.reviews?.length >= 5 ? 5 : coachDetail?.reviews?.length}
                                >
                                    {coachDetail?.reviews?.map((item, index) => {
                                        return <CoachCard item={item} />
                                    })}
                                </SiteSlider> :
                                <NoData page="coach-review" />
                            }
                        </div>
                    </div>
                </>
            }
            <Footer />
        </div>
    );
};

export default CoachReview;