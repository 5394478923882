import { useEffect ,useState } from "react";
import Account from "../account/Account";
import badge from "../../assets/images/subscriptionDetail/Badge.svg"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { selectSubscriptionDetail, setSubscriptionDetail } from "../../redux/subscriptionSlice";
import label from "../../assets/images/subscriptionDetail/Label.svg"
import ButtonWithArrow from "../../components/common/button/ButtonWithArrow";
import CoverImg from "../../assets/images/Subscription/cover.svg"
import check from "../../assets/images/Subscription/CheckIcon.svg";
import { IoMdStar } from "react-icons/io";

//importing styles
import "../../assets/scss/components/subscriptionDetail/subscriptionDetail.scss"
import { getSubscriptionStatus } from "../../services/subscription.service";
import Button from "../../components/common/button/Button";
import SummaryModal from "../../components/Modals/RateAndReviewModals/SummaryModal";
import { selectLearnAndEarnSubscriptionDetail } from "../../redux/learnAndEarnSubscriptionSlice";

const SubscriptionDetail = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false)
    const [subscriptionType , setSubscriptionType] = useState('normal')
    const [selected , setSelected] = useState('LEP')

    const dispatch = useDispatch();
    const subcription = useSelector(selectSubscriptionDetail)
    const subscriptionLEP = useSelector(selectLearnAndEarnSubscriptionDetail)

    const fetchUserSubscriptionStatus = async () => {
        const result = await getSubscriptionStatus();
        dispatch(setSubscriptionDetail(result?.data));
    };

    useEffect(() => {
        fetchUserSubscriptionStatus();
        // eslint-disable-next-line
    }, []);

    const onHide = () => {
        setShow(!show)
    }

    const onNormal=()=>{
        setShow(true)
        setSubscriptionType('normal')
    }

    const onLEP=()=>{
        setShow(true)
        setSubscriptionType('LEP')
    }
    return (
        <Account>
            <div className="w-100">
                <h3 className="fw-bold">Subscription</h3>
                <div className="d-md-flex gap-3">
                    <div className="px-3 pt-3 mb-3-my bg-white border rounded w-md-50 w-100 ">
                        <div className="mb-2">

                            <p className="fs-6">Your Current Subscription Plan</p>
                            {
                                subcription?.status !== "inactive" ?

                                    <div className="d-flex justify-content-between">

                                        <h3 className="text-primary fw-bold">Premium</h3>
                                        <img src={badge} alt="icon" />
                                    </div>
                                    :
                                    <div className="text-primary text-center"><img src={label} alt="icon" /></div>
                            }
                        </div>
                    </div>
                    <div className="p-3 bg-white border rounded w-md-50 w-100">
                        <p className="fs-6">Valid Duration</p>
                        {
                            subcription?.status !== "inactive" ?

                                <h3 className="text-primary fw-bold">{subcription?.days_remaining} days</h3>
                                :
                                <div className="text-primary text-center"><img src={label} alt="icon" /></div>

                        }
                    </div>
                </div>
                {/* {
                    subcription?.status === "active" &&

                        <div className="d-sm-flex align-items-start justify-content-between border p-3 mt-3 mb-5 rounded">
                            <div className="">
                                <h3 className="text-primary fw-bold">Yearly</h3>
                                <p className="fs-6"> Yearly Learning Package</p>
                            </div>
                            {
                                subcription?.amount &&

                                <h3 className="text-primary fw-bold">${subcription?.amount}</h3>
                            }
                            <button className="bg-success border-0 p-2 rounded text-white">Activated</button>
                        </div>
                        
                            
                } */}
                {!(subscriptionLEP?.is_enpl) &&
                <>
                <div className="w-100 my-5">
                    <img src={CoverImg} alt='cover' className="w-100" />
                </div>
                <div className="subscriber-container gap-5 w-100 my-5">
                    <div className={`shadow-sm rounded my-4  subscriber-card   bg-card-slati `} onClick={()=>{setSelected('normal')}}>
                        <h3 className="fw-bold text-end text-primary p-2">$12</h3>
                        <div className="p-3 px-5 ">
                            <h4 className="fw-bold text-center">1-Year</h4>
                            <h5 className="fw-semibold text-center"> Premium Subscription</h5>
                            <p className="text-center text-white fw-semibold mx-auto" style={{ background: "#7B7B7B", width: "110px" }}>Value Pack</p>
                            <div>
                                <Button
                                    name={"Subscribe Now"}
                                    onClick={onNormal}
                                />
                            </div>
                            <hr style={{ color: "#98a2b3" }}  className="my-3"/>
                            <p className="fw-bold text-start">FEATURES</p>

                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills OnLive</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Unlimited Daily LIVE Classes</h6>
                                </div>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Cource Assessment</h6>
                                </div> <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Accredited Certificates</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills OnTheGo</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Self-Paced Video Learning</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills OnStage</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Industry Leaders' Talks & Sharing</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills OnFerence</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Online Learning Conference</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills On-1</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Short and Focused Learning Contents</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills Special</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Tailored Learning Campaigns<br/> and Activities</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">Learner's Profile</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Learning Dashboard</h6>
                                </div>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Assessment Report</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">Support</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Full Dedicated Support</h6>
                                </div>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Community of Like-Minded Learners</h6>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                    <div className={`shadow-sm rounded my-4  subscriber-card   bg-card-slati `} onClick={()=>{setSelected('LEP')}}>
                        <h3 className="fw-bold text-end text-primary p-2">$24</h3>
                        <div className="p-3 px-5 ">
                            <h4 className="fw-bold text-center">2-Year</h4>
                            <h5 className="fw-semibold text-center"> Premium Subscription</h5>
                            <p className="text-center text-white fw-semibold mx-auto" style={{ background: "#B74073", width: "140px" }}>Most Popular</p>
                            <div>
                                <Button
                                    name={"Subscribe Now"}
                                    onClick={onLEP}
                                />
                            </div>
                            <hr style={{ color: "#98a2b3" }}  className="my-3"/>
                            <div className="d-flex ">
                                <IoMdStar style={{color:"#FFD700"}} size={24} />
                                <h5 className="fw-bold text-start">EXTRA BENEFITS</h5>
                                <IoMdStar style={{color:"#FFD700"}} size={24} />
                            </div>

                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">Learning Point System</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Accumulate Points</h6>
                                </div>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Reward Selection</h6>
                                </div> 
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Added Learning Experience</h6>
                                </div>
                                 
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Expansive Network and Community</h6>
                                </div>
                            </div>
                            <hr style={{ color: "#98a2b3" }}  className="my-3"/>

                            <p className="fw-bold text-start">FEATURES</p>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills OnLive</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Unlimited Daily LIVE Classes</h6>
                                </div>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Cource Assessment</h6>
                                </div> <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Accredited Certificates</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills OnTheGo</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Self-Paced Video Learning</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills OnStage</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Industry Leaders' Talks & Sharing</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills OnFerence</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Online Learning Conference</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills On-1</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Short and Focused Learning Contents</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">ReSkills Special</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Tailored Learning Campaigns<br/> and Activities</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">Learner's Profile</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Learning Dashboard</h6>
                                </div>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Assessment Report</h6>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6 className="text-primary fw-semibold">Support</h6>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Full Dedicated Support</h6>
                                </div>
                                <div className="d-flex gap-2">
                                    <img src={check} className="" alt="check" style={{width:"25px"}} />
                                    <h6 className="my-2 ">Community of Like-Minded Learners</h6>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                </div> 
                </>}
                
            </div>
            <SummaryModal 
                show={show}
                onHide={onHide}
                subscriptionType={subscriptionType}
            />
        </Account>
    );
};

export default SubscriptionDetail;