import React, { useEffect, useState } from 'react';
import Home from '../../assets/images/Sidebar/Home.png'
import Navbar from '../../components/common/navbar/Navbar';
import Footer from '../../components/common/footer/Footer';
import FeaturedCoachCard from '../../components/common/cards/FeaturedCoachCard/FeaturedCoachCard';
import { getFeaturedCoaches } from '../../services/coach.service';
import Paginate from '../../components/common/paginate/Paginate';
import { useDispatch } from 'react-redux';
// Style
import '../../assets/scss/pages/allCoaches/allCoaches.scss'
// import { setCoach } from '../../redux/coachSlice';
import { useSelector } from 'react-redux';
import { selectCoachDetail ,setCoach } from '../../redux/coachSlice';
import CoachPaginate from '../../components/common/paginate/CoachPaginate';
import { useCallback } from 'react';
// import searchIcon from "../../../assets/images/Navbar/search-Icon.svg";
import searchIcon from '../../assets/images/Navbar/search-Icon.svg'


const AllCoaches = () => {
    const [featuredCoaches, setFeaturedCoaches] = useState();
    const [totalItem, setTotalItem] = useState()
    const [searchClass, setSearchClass] = useState();

    const dispatch = useDispatch();

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };

    const handleSearch = async (event) => {
        const { value } = event?.target;
        setSearchClass(value)
    };

    const optimisedVersion = useCallback(debounce(handleSearch), []);
   
  
    
    return (
        <div >
            <Navbar />
            <div className='custom-container max-w-home'>
                <div className='mx-5 main-coach-container '>
                    <div className='my-4'>
                        <div className='d-flex mb-3'>
                            <img src={Home} alt='' style={{ height: "15px", width: "15px" }} className='m-2' />
                            <div className='py-1 px-2 font-mute'>/</div>
                            <div className='m-1 my-account px-2 rounded fw-semibold '><small >Coaches</small></div>
                        </div>
                        <div className='coach-explore-all-coaches '>
                            <div className="d-flex align-items-center  mb-md-0 me-md-auto  text-decoration-none">
                                <h4 className="fs-5 d-none d-sm-inline fw-bold px-2 min-width-0">Coaches</h4>
                            </div>
                            <div className="d-flex position-relative search-width ">
                            <div
                                className="search-main-container"
                            >
                                <img src={searchIcon} alt="search-icon" className="search-icon" />
                                <input
                                    className={ `bg-white form-control ml-2 border-0 mx-2 `}
                                    type="search"
                                    id="input-field"
                                    placeholder="Search Coach" 
                                    autoComplete="off"
                                    aria-label="Search"
                                    onChange={optimisedVersion}
                                />
                                
                                </div>
                            </div>

                        </div>
                        
                    </div>

                    <div className=' w-100'>
                        <CoachPaginate searchClass={searchClass}/>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default AllCoaches;